export default class UserInfo2 {
  displayName: string;
  providerId: string;
  email: string;
  uid: string;
  isAnonymous: boolean;
  photoURL: string | null; // Add this line
  documentProcessedTotal: number = 0;
  documentProcessedLastDay: number = 0;
  documentProcessedLastDate: Date = new Date();
  lastLogin: Date = new Date();
  businessName: string = '';
  businessAddress: string = '';
  createdOn: Date = new Date();
  coupon: string = '';
  documentProcessedLastDayDate?: Date = new Date();

  constructor(user: any) {
    this.displayName = user.displayName || (user.providerData[0]?.displayName);
    this.providerId = user.providerData[0]?.providerId || '';
    this.email = user.email || user.providerData[0]?.email || '';
    this.uid = user.uid;
    this.isAnonymous = user.isAnonymous || false;
    this.photoURL = user.photoURL || user.providerData[0]?.photoURL || null; // Add this line
  }
}
